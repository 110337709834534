import React from 'react';
import RankText from './rank-text';
import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  CheckCircleIcon,
  PlusCircleIcon,
  XCircleIcon
} from '@heroicons/react/solid';
import EntrantName from './entrant-name';
import EntrantLocation from './entrant-location';

const PicksTable = ({
  actionsDisabled,
  event,
  picks,
  heading,
  movePick,
  removePick,
  picksLoading,
  communityPicks,
  comparisonPicks,
  maxPicksPerField,
  showAthleteInfoModal
}: {
  actionsDisabled: boolean;
  event: any;
  picks: any[];
  comparisonPicks?: any[];
  maxPicksPerField: number;
  heading: string;
  movePick?: any;
  removePick?: any;
  picksLoading: boolean;
  communityPicks?: boolean;
  showAthleteInfoModal?: any;
}) => {
  return (
    <div className="mb-7 mr-0 flex-1 overflow-x-scroll border-b border-gray-300 shadow sm:overflow-hidden">
      <table className="min-w-full table-auto divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              className="bg-zinc-900 relative w-10 overflow-hidden whitespace-nowrap border-b border-r border-gray-600 px-6 py-4 text-left text-xs font-normal uppercase text-white"
              colSpan={100}
            >
              <span className="absolute inset-0 transform bg-gradient-to-r from-transparent via-white/10 to-white/10"></span>
              {heading}
              {!picksLoading && !communityPicks && (
                <span className="border-zinc-700 bg-zinc-900 absolute right-3 top-3 rounded-sm border px-2 py-1 text-xs font-medium text-white">
                  {picks.length} / {maxPicksPerField}
                </span>
              )}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {picks.map((entrant, i) => {
            return (
              <tr
                key={entrant.id}
                className={`${
                  entrant.started_race ? 'hover:bg-gray-50' : 'bg-gray-200 line-through'
                }`}
              >
                <td className="td-compact">
                  <div className="sm:max-w-fit max-w-[150px] text-sm text-gray-900">
                    <span className="mr-3 align-top text-base">
                      <RankText rank={i + 1} />
                    </span>
                    <EntrantName
                      entrant={entrant}
                      onClick={() => showAthleteInfoModal(entrant)}
                      showImage={false}
                      includePerformance
                    />
                  </div>
                </td>
                <td className="td-compact hidden sm:table-cell">
                  <EntrantLocation event={event} entrant={entrant} />
                </td>
                <td className="td-compact flex justify-end text-sm font-medium">
                  {communityPicks &&
                    (Boolean((comparisonPicks || []).find((e) => e.id === entrant.id)) ? (
                      <button
                        className="mr-1 flex items-center p-2 text-xs disabled:text-gray-300"
                        disabled={actionsDisabled}
                      >
                        <CheckCircleIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      </button>
                    ) : (
                      <button
                        className="invisible mr-1 flex items-center p-2 text-xs"
                        disabled={actionsDisabled}
                      >
                        <PlusCircleIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      </button>
                    ))}
                  {!communityPicks && (
                    <>
                      <button
                        className="text-slate disabled:text-slate-300 mr-1 flex items-center p-1 text-xs sm:p-2"
                        onClick={() => movePick(entrant, i, i - 1)}
                        title="Move Up"
                        disabled={i === 0 || actionsDisabled}
                      >
                        <ArrowCircleUpIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      </button>
                      <button
                        className="text-slate disabled:text-slate-300 mr-1 flex items-center p-1 text-xs sm:p-2"
                        onClick={() => movePick(entrant, i, i + 1)}
                        title="Move Down"
                        disabled={i === picks.length - 1 || actionsDisabled}
                      >
                        <ArrowCircleDownIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      </button>
                      <button
                        className="flex items-center p-1 text-xs text-red-500 disabled:text-red-200 sm:p-2"
                        onClick={() => removePick(entrant)}
                        title="Remove Pick"
                        disabled={actionsDisabled}
                      >
                        <XCircleIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      </button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
          {picks.length === 0 && !picksLoading && (
            <tr>
              <td className="td-compact py-4 text-xs">
                {!communityPicks &&
                  ` Use the "Pick" button below to make ${maxPicksPerField} picks per field.`}
              </td>
            </tr>
          )}
          {picks.length === 0 && picksLoading && (
            <tr>
              <td className="td-compact py-4 text-xs italic">Loading your picks...</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PicksTable;

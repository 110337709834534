import { ExternalLinkIcon, LinkIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../context/user-context';
import api from '../lib/api';
import AthleteTeams from './athlete-teams';
import NationalityFlag from './nationality-flag';
import ReactMarkdown from 'react-markdown';
import {
  DUVStatsIcon,
  ITRAIcon,
  InstagramIcon,
  StravaIcon,
  UTMBIcon,
  UltraSignupIcon,
  WMRAIcon
} from './icons';
import Tooltip from './tooltip';
import { DEFAULT_PROFILE_IMAGE_URL } from '../lib/constants';

interface AthleteProfileModalProps {
  athleteId?: number;
  firstName: string;
  lastName: string;
  location: string;
  notes?: string;
  onClose: () => void;
  resultsYear?: number;
  resultId?: number;
  itraId?: string;
  utmbId?: string;
}

const AthleteProfileModal = ({
  athleteId,
  firstName,
  lastName,
  location,
  notes = undefined,
  onClose,
  resultsYear = undefined,
  resultId = undefined,
  itraId = undefined,
  utmbId = undefined
}: AthleteProfileModalProps) => {
  const user = useContext(UserContext);
  const [athlete, setAthlete] = useState(null);
  const [showTeamTenures, setShowTeamTenures] = useState(false);

  const canonicalFirstName = athlete?.first_name || firstName;
  const canonicalLastName = athlete?.last_name || lastName;

  useEffect(() => {
    if (athleteId) {
      api(user.accessToken)
        .getAthlete(athleteId, resultsYear)
        .then((response) => {
          response.json().then((body) => {
            setAthlete(body.data);
          });
        });
    }
  }, [athleteId]);

  if (athleteId && !athlete) {
    return null;
  }

  const runStats = athlete?.strava_stats?.run_summary;
  const canonicalItraId = athlete?.itra_id || itraId;
  const canonicalUtmbUrl =
    athlete?.utmb_url || (utmbId ? `https://utmb.world/en/runner/${utmbId}` : undefined);

  return (
    <div
      id="athleteModal"
      tabIndex={-1}
      aria-hidden="true"
      className="h-modal fixed inset-0 left-0 right-0 top-0 z-50 h-full w-full overflow-y-auto overflow-x-hidden bg-gray-900/60"
    >
      <div className="relative mx-auto mt-16 h-auto w-full max-w-lg p-4">
        <div className="relative border border-gray-800 bg-white shadow">
          <div className="pattern-profile flex items-start justify-between border-b border-gray-800 p-4 pb-3 shadow-sm">
            <div className="flex justify-between">
              {Boolean(athlete?.profile_image_urls?.thumb) ? (
                <img
                  src={athlete?.profile_image_urls?.thumb || DEFAULT_PROFILE_IMAGE_URL}
                  className="mr-3 h-14 w-14 rounded-full border border-white"
                />
              ) : (
                <div
                  className={`bg-purple-haze-400 mr-3 inline-flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 shadow`}
                >
                  <span className="font-mono text-lg text-white">
                    {[canonicalFirstName, canonicalLastName]
                      .filter((n) => n)
                      .map((n) => n[0].toUpperCase())
                      .join('')}
                  </span>
                </div>
              )}
              <div>
                <h3 className="mb-1 flex items-center text-lg font-medium text-white">
                  <span className="text-shadow-sm shadow-black/30 mr-3">
                    {canonicalFirstName} {canonicalLastName}
                  </span>
                  <NationalityFlag country={athlete?.country} />
                </h3>
                <div className="text-shadow-sm shadow-black/20 mt-1 flex items-center text-sm font-normal capitalize text-white sm:mt-0">
                  <LocationMarkerIcon
                    className="text-purple-haze-800 mr-1 h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  {athlete?.location || location}
                </div>
              </div>
            </div>
            <button
              type="button"
              className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-xs text-white hover:text-white"
              data-modal-toggle="athleteModal"
              onClick={onClose}
            >
              <svg
                className="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className="space-y-6 p-4 pb-8">
            {Boolean(notes) && (
              <div>
                <h2 className="athlete-profile-subheading">Notes</h2>
                <ReactMarkdown className="markdown text-xs leading-5" linkTarget="_blank">
                  {notes}
                </ReactMarkdown>
              </div>
            )}
            <div>
              <h2 className="athlete-profile-subheading">Links</h2>
              <div className="grid grid-cols-2 gap-4 text-sm text-gray-600 sm:grid-cols-2">
                {Boolean(athlete?.personal_url) && (
                  <p>
                    <LinkIcon className="mr-3 inline h-4 w-4" />
                    <a
                      href={athlete?.personal_url}
                      target="_blank"
                      className="hyperlink decoration-gray-700 underline"
                      title={athlete?.personal_url.replace(/(^\w+:|^)\/\//, '')}
                    >
                      Personal
                    </a>
                  </p>
                )}
                <p>
                  <UltraSignupIcon />
                  <a
                    href={
                      athlete?.ultrasignup_url ||
                      `https://ultrasignup.com/results_participant.aspx?fname=${canonicalFirstName}&lname=${canonicalLastName}`
                    }
                    target="_blank"
                    className="hyperlink decoration-gray-700 underline"
                  >
                    UltraSignup
                  </a>
                </p>
                <p>
                  <DUVStatsIcon />
                  <a
                    href={
                      athlete?.duv_url ||
                      `https://statistik.d-u-v.org/searchrunner.php?sname=${canonicalLastName}%2C+${canonicalFirstName}&Submit.x=0&Submit.y=0`
                    }
                    target="_blank"
                    className="hyperlink decoration-gray-700 underline"
                  >
                    DUV Stats
                  </a>
                </p>
                {Boolean(canonicalItraId) && (
                  <p>
                    <ITRAIcon />
                    <a
                      href={`https://itra.run/RunnerSpace/${canonicalItraId}`}
                      target="_blank"
                      className="hyperlink decoration-gray-700 underline"
                    >
                      ITRA
                    </a>
                  </p>
                )}
                {Boolean(canonicalUtmbUrl) && (
                  <p>
                    <UTMBIcon />
                    <a
                      href={canonicalUtmbUrl}
                      target="_blank"
                      className="hyperlink decoration-gray-700 underline"
                    >
                      UTMB
                    </a>
                  </p>
                )}
                {Boolean(athlete?.wmra_url) && (
                  <p>
                    <WMRAIcon />
                    <a
                      href={athlete.wmra_url}
                      target="_blank"
                      className="hyperlink decoration-gray-700 underline"
                    >
                      WMRA
                    </a>
                  </p>
                )}
                {Boolean(athlete?.strava_url) && (
                  <p>
                    <StravaIcon />
                    <a
                      href={athlete.strava_url}
                      target="_blank"
                      className="hyperlink decoration-gray-700 underline"
                    >
                      Strava
                    </a>
                  </p>
                )}
                {Boolean(athlete?.instagram_handle) && (
                  <p>
                    <InstagramIcon />
                    <a
                      href={`https://instagram.com/${athlete.instagram_handle}`}
                      target="_blank"
                      className="hyperlink decoration-gray-700 underline"
                    >
                      Instagram
                    </a>
                  </p>
                )}
              </div>
            </div>
            {Boolean(runStats) && (
              <div>
                <h2 className="athlete-profile-subheading">Training</h2>
                <p className="mb-8 text-xs text-gray-700">
                  Stats summarize last four weeks of running.
                </p>
                <table className="mb-2 mt-2 w-full table-auto text-sm">
                  <thead className="border-b border-gray-300 text-left font-mono font-medium uppercase">
                    <tr>
                      <th></th>
                      <th className="font-medium">Weekly Avg</th>
                      <th className="font-medium">Total</th>
                    </tr>
                  </thead>
                  <tbody className="text-xs">
                    <tr>
                      <td className="pr-2 pt-4 font-mono text-sm font-medium uppercase">
                        Distance
                      </td>
                      <td className="pr-2 pt-4">
                        {runStats.avg_distance_miles_s} ({runStats.avg_distance_km_s})
                      </td>
                      <td className="pr-2 pt-4">
                        {runStats.distance_miles_s} ({runStats.distance_km_s})
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-2 pt-4 font-mono text-sm font-medium uppercase">Vert</td>
                      <td className="pr-2 pt-4">
                        {runStats.avg_elevation_gain_ft_s} ({runStats.avg_elevation_gain_meters_s})
                      </td>
                      <td className="pr-2 pt-4">
                        {runStats.elevation_gain_ft_s} ({runStats.elevation_gain_meters_s})
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-2 pt-4 font-mono text-sm font-medium uppercase">Time</td>
                      <td className="pr-2 pt-4">{runStats.avg_elapsed_time_s}</td>
                      <td className="pr-2 pt-4">{runStats.elapsed_time_s}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="flex place-content-between">
                  <p className="pt-[20px] text-xs text-gray-700">
                    <a href={athlete?.strava_url} className="hyperlink underline" target="_blank">
                      View on Strava
                    </a>
                  </p>
                  <img
                    className="h-[40px]"
                    src="https://d2gwq01zobnbgs.cloudfront.net/assets/strava/api_logo_pwrdBy_strava_stack_light.svg"
                  />
                </div>
              </div>
            )}
            {Boolean(athlete?.teams?.length || athlete?.team_tenures?.length) && (
              <div>
                <h2 className="athlete-profile-subheading">Teams</h2>
                <div className="flex flex-col items-start space-y-4">
                  {Boolean(athlete?.teams?.length) && (
                    <div>
                      <AthleteTeams teams={athlete.teams} />
                    </div>
                  )}
                  {Boolean(athlete?.team_tenures?.length) && (
                    <>
                      <button
                        className="hyperlink block text-xs underline"
                        onClick={() => setShowTeamTenures(!showTeamTenures)}
                      >
                        {showTeamTenures ? 'Hide' : 'View'} team history
                      </button>
                      {showTeamTenures && (
                        <table className="mt-4 w-full table-auto text-sm">
                          <thead className="border-b border-gray-300 text-left font-mono uppercase">
                            <tr>
                              <th>Team</th>
                              <th>Start Year</th>
                              <th>End Year</th>
                            </tr>
                          </thead>
                          <tbody className="text-xs">
                            {athlete.team_tenures.map((team_tenure) => (
                              <tr key={team_tenure.id}>
                                <td className="pr-2 pt-4">{team_tenure.team.name}</td>
                                <td className="whitespace-nowrap pr-2 pt-4">
                                  {team_tenure.start_year || '-'}
                                </td>
                                <td className="whitespace-nowrap pr-2 pt-4">
                                  {team_tenure.end_year || '-'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {(athlete?.results || []).length > 0 && Boolean(resultsYear) && (
              <div>
                <h2 className="athlete-profile-subheading">
                  {Boolean(resultId) ? 'Result' : 'Results'}
                </h2>
                <br />
                <div>
                  <table className="w-full table-auto text-sm">
                    <thead className="border-b border-gray-300 text-left font-mono uppercase">
                      <tr>
                        <th>Date</th>
                        <th>Race</th>
                        <th className="py-2 pr-2">Place</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody className="text-xs">
                      {athlete.results
                        .filter((r) => !resultId || r.id === resultId)
                        .map((result) => (
                          <tr key={result.id}>
                            <td className="whitespace-nowrap pr-2 pt-4">
                              {result.race.formatted_date}
                            </td>
                            <td className="pr-2 pt-4">
                              <a
                                target="_blank"
                                href={result.race.results_url}
                                className="hyperlink underline"
                              >
                                {result.race.name}
                              </a>
                            </td>
                            <td className="whitespace-nowrap pr-2 pt-4">
                              {!result.dnf ? `${result.place_field}` : 'DNF'}
                              {result.course_record && <span className="ml-2">👑</span>}
                            </td>
                            <td className="whitespace-nowrap pt-4">
                              {result.dnf ? '-' : result.formatted_finish_time}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {(athlete.results || []).some(
                    (r) => (!resultId || resultId == r.id) && r.course_record
                  ) && (
                    <p className="mt-4 border-t border-gray-300 pt-4 text-xs">
                      <span className="mr-1">👑</span>
                      <span className="italic">Course Record</span>
                    </p>
                  )}
                </div>
              </div>
            )}
            {Boolean(athlete?.athlete_media?.length) && (
              <div>
                <h2 className="athlete-profile-subheading">Media</h2>
                <br />
                <ul>
                  {(athlete.athlete_media || []).map((media) => (
                    <li className="mb-3" key={media.id}>
                      <ExternalLinkIcon className="mr-1 inline h-4 w-4 text-gray-600" />
                      <a href={media.url} target="_blank" className="hyperlink text-xs underline">
                        {media.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {false && Boolean(athlete) && (
              <div>
                <button className="bg-lazer-lemon-500 border border-black bg-white p-2 px-3 font-mono text-xs uppercase text-black">
                  Claim Profile
                </button>
                <Tooltip>
                  Is this you? Claim your profile to update
                  <br />
                  your profile image, add a personal link,
                  <br /> and more.
                </Tooltip>
              </div>
            )}
          </div>
          <div className="pattern-profile flex items-center space-x-2 border-t border-gray-800 p-4">
            <button
              data-modal-toggle="athleteModal"
              className="text-xs text-white underline"
              onClick={onClose}
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AthleteProfileModal;
